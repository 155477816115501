<template>
  <div class="solution">
    <div class="page-1">
      <div class="flex-col p-a">
        <h1 class="m0 w-100">高频次广告解决方案</h1>
        <p>
          <span>丰富的广告资源 + 混合变现模式，解决填充率不足和收益提升难</span>
          <br />
          <span>的问题</span>
        </p>
        <div>
          <my-button name="免费使用" @click.stop="LogInClick('login')" />
        </div>
      </div>
    </div>
    <div class="page-2 flex-col align-center">
      <h2>汇聚全球 200,000+ 优质广告主，最大化满足您的广告填充</h2>
      <div class="solution-image2"></div>
    </div>
    <div class="page-3 flex-col align-center">
      <h2>智能瀑布流 + 应用内竞价，混合变现模式助您快速提升收益</h2>
      <div class="solution-image3"></div>
    </div>
    <div class="page-4 flex-col align-center">
      <h2>亮点功能</h2>
      <div class="flex-row flex-center center4">
        <div
          class="flex-row flex-center center-child"
          v-for="da in data"
          :key="da.head"
        >
          <div
            class="solution-images"
            :style="{ backgroundImage: `url(${da.url})` }"
          ></div>
          <div class="solution-txt">
            <h3>{{ da.head }}</h3>
            <p>{{ da.txt }}</p>
          </div>
        </div>
      </div>
      <my-button name="免费使用" @click.stop="LogInClick('login')" />
    </div>
  </div>
</template>

<script lang="ts">
import MyButton from "@/components/MyButton.vue";
import { defineComponent } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  components: { MyButton },
  setup() {
    const data = [
      {
        head: "SDK接入",
        txt: "轻量化的SDK，便捷的接入方式，极大降低广告接入门槛",
        url: "./img/high/icon1.png",
      },
      {
        head: "聚合管理",
        txt: "轻松管理多平台的应用、广告位和广告源，让你的广告资源一目了然",
        url: "./img/high/icon2.png",
      },
      {
        head: "智能策略",
        txt: "系统为您自动优化广告，降低运营成本，促进收益增长",
        url: "./img/high/icon3.png",
      },
    ];
    return {
      data,
      LogInClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.solution {
  width: 100%; ///;
  font-family: PingFangSC-Semibold, PingFang SC;
  // font-weight: 600;
  font-size: 1.25vw;
  .page-1 {
    width: 100%; ///;
    height: 31.25vw;
    background: linear-gradient(
      176deg,
      rgba(77, 120, 231, 0) 0%,
      rgba(36, 63, 131, 0.1) 46%,
      rgba(36, 63, 131, 0.1) 46%,
      #051c50 75%,
      #001034 100%
    );
    background-image: url(~@/assets/image/high/high1_head.png);
    background-size: 100%;
    div {
      left: 14.323vw;
      top: 11.094vw;
      h1 {
        font-size: 2.5vw;
        color: #ffffff;
        line-height: 4.375vw;
      }
      p {
        font-size: 1.25vw;
        color: #ffffff;
        line-height: 1.719vw;
        margin: 1.719vw 0 1.25vw;
      }
    }
  }
  .page-2 {
    height: 41.667vw;
    background-image: url(~@/assets/image/high/high1_bg2.png);
    background-position: center;
    background-size: 100%;
    h2 {
      margin: 3.281vw 0 4.167vw;
      color: #000;
      font-size: 1.667vw;
    }
  }
  .page-3 {
    height: 41.667vw;
    background: linear-gradient(169deg, #142d67 0%, #000000 100%);
    h2 {
      margin: 3.281vw 0 1.6vw;
      color: #fff;
      font-size: 1.667vw;
    }
    .solution-image3 {
      width: 66.25vw;
      height: 32.969vw;
      background-image: url(~@/assets/image/high/high1_photo.png);
      background-size: 100% auto;
    }
  }
  .page-4 {
    height: 31.25vw;
    h2 {
      margin: 3.281vw 0 5.521vw;
      color: #000;
      font-size: 1.667vw;
    }
    background: linear-gradient(
      180deg,
      rgba(223, 235, 242, 0.5) 0%,
      #ffffff 100%
    );
    .center4 {
      margin-bottom: 4.167vw;
      .center-child {
        margin: 0 2.083vw;
        .solution-images {
          width: 6.25vw;
          height: 6.25vw;
          background-size: 100% auto;
          // border: 0.052vw solid #979797;
        }

        .solution-txt {
          margin-left: 1.25vw;
          width: 17.865vw;
          font-size: 0.833vw;
          h3 {
            font-size: 1.667vw;
            margin: 0.833vw 0 0;
          }
        }
      }
    }
  }
}
</style>
