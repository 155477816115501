
import MyButton from "@/components/MyButton.vue";
import { defineComponent } from "vue";
import LogInClick from "../use/LogInClick";

export default defineComponent({
  components: { MyButton },
  setup() {
    const data = [
      {
        head: "SDK接入",
        txt: "轻量化的SDK，便捷的接入方式，极大降低广告接入门槛",
        url: "./img/high/icon1.png",
      },
      {
        head: "聚合管理",
        txt: "轻松管理多平台的应用、广告位和广告源，让你的广告资源一目了然",
        url: "./img/high/icon2.png",
      },
      {
        head: "智能策略",
        txt: "系统为您自动优化广告，降低运营成本，促进收益增长",
        url: "./img/high/icon3.png",
      },
    ];
    return {
      data,
      LogInClick,
    };
  },
});
